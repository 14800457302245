const BuildingIcon = () => {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_86_4652)">
        <path
          d="M2 25C2 11.7452 12.7452 1 26 1C39.2548 1 50 11.7452 50 25C50 38.2548 39.2548 49 26 49C12.7452 49 2 38.2548 2 25Z"
          fill="#A1CFD1"
          shapeRendering="crispEdges"
        />
        <path
          d="M2.5 25C2.5 12.0213 13.0213 1.5 26 1.5C38.9787 1.5 49.5 12.0213 49.5 25C49.5 37.9787 38.9787 48.5 26 48.5C13.0213 48.5 2.5 37.9787 2.5 25Z"
          stroke="#EAECF0"
          shapeRendering="crispEdges"
        />
        <path
          d="M21.5 24H18.6C18.0399 24 17.7599 24 17.546 24.109C17.3578 24.2049 17.2049 24.3578 17.109 24.546C17 24.7599 17 25.0399 17 25.6V34M30.5 24H33.4C33.9601 24 34.2401 24 34.454 24.109C34.6422 24.2049 34.7951 24.3578 34.891 24.546C35 24.7599 35 25.0399 35 25.6V34M30.5 34V19.2C30.5 18.0799 30.5 17.5198 30.282 17.092C30.0903 16.7157 29.7843 16.4097 29.408 16.218C28.9802 16 28.4201 16 27.3 16H24.7C23.5799 16 23.0198 16 22.592 16.218C22.2157 16.4097 21.9097 16.7157 21.718 17.092C21.5 17.5198 21.5 18.0799 21.5 19.2V34M36 34H16M25 20H27M25 24H27M25 28H27"
          stroke="#101828"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_86_4652"
          x="0"
          y="0"
          width="52"
          height="52"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_86_4652"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_86_4652"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default BuildingIcon;
