import { FC } from "react";
import styles from "./styles.module.scss";
import imageDesktop1 from "../../../../../assets/images/chat_desktop_1.png";
import imageDesktop2 from "../../../../../assets/images/chat_desktop_2.png";
import imageMobile1 from "../../../../../assets/images/chat_mobile_1.png";
import imageMobile2 from "../../../../../assets/images/chat_mobile_2.png";
import { ItemIcon } from "../../../../../types";
import { CONTENT } from "../../../../../constants/content/chat-bots";
import { getIcon } from "../../../../../helpers/getIcon";

interface IChatBotsProps {
  isMobile: boolean;
}

const ChatBots: FC<IChatBotsProps> = ({ isMobile }) => {
  const renderImage = (type: ItemIcon) => {
    switch (type) {
      case "square":
        return isMobile ? imageMobile1 : imageDesktop1;
      case "rocket":
        return isMobile ? imageMobile2 : imageDesktop2;
      default:
        return isMobile ? imageMobile1 : imageDesktop1;
    }
  };

  return (
    <section className={styles.container} id="chatbot">
      <h2>{CONTENT.TITLE}</h2>
      <p>{CONTENT.DESCRIPTION}</p>
      <div className={styles.wrapper}>
        {CONTENT.ITEMS.map((item) => (
          <div className={styles.fullWrapper} key={item.type}>
            <div className={styles.imageWrapper}>
              <img
                src={renderImage(item.type)}
                alt={CONTENT.TITLE}
                className={styles.image}
              />
            </div>
            <div className={styles.itemIconWrapper}>
              <div>{getIcon(item.type)}</div>
              <div className={styles.itemWrapper}>
                <h4>{item.title}</h4>
                <p>{item.text}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default ChatBots;
