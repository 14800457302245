import { Item } from "../../types";

export const CONTENT = {
  TITLE: "Зачем нужен чат-бот?",
  ITEMS: [
    {
      type: "clock-refresh",
      title: "Круглосуточная доступность",
      text: "Отвечайте на вопросы клиентов в любое время дня и ночи.",
    },
    {
      type: "zap",
      title: "Мгновенное реагирование",
      text: "Предоставляйте быстрые ответы, улучшая удовлетворенность клиентов.",
    },
    {
      type: "line-chart-down",
      title: "Экономия времени",
      text: "Автоматизируйте рутинные задачи, освобождая время сотрудников для более сложных обращений.",
    },
    {
      type: "hourglass",
      title: "Снижение затрат",
      text: "Уменьшайте расходы на поддержку за счет автоматизации частых запросов.",
    },
    {
      type: "chart-breakout-square",
      title: "Повышение лояльности",
      text: "Экономьте время клиента, помогая найти нужную информацию и, при необходимости применяя разные сценарии.",
    },
    {
      type: "settings",
      title: "Управление сценариями",
      text: "Получите инструмент для разработки редактирования сценариев диалогов вашего бота.",
    },
  ] as Item[],
  BUTTON: "Заявка на демо",
};
