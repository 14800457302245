import { useEffect, useState } from "react";
import { isMobile } from "../../helpers/isMobile";
import styles from "./styles.module.scss";
import {
  Changes,
  ChatBots,
  ForWhat,
  Format,
  Possibilities,
  Preview,
} from "./components/content";

const Main = () => {
  const [mobile, setMobile] = useState(isMobile());

  useEffect(() => {
    const handleResize = () => setMobile(isMobile());

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <main className={styles.main}>
      <div className={styles.wrapper}>
        <Preview isMobile={mobile} />
        <Possibilities isMobile={mobile} />
        <ChatBots isMobile={mobile} />
        <ForWhat />
        <Changes isMobile={mobile} />
        <Format />
      </div>
    </main>
  );
};

export default Main;
