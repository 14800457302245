import styles from "./styles.module.scss";
import imageDesktop from "../../../../../assets/images/format_desktop.png";
import { CONTENT } from "../../../../../constants/content/format";
import { DescriptionCard, Form } from "../../shared";

const Format = () => {
  return (
    <section className={styles.container} id="demo">
      <h2>{CONTENT.TITLE}</h2>
      <div className={styles.solutionsWrapper}>
        {CONTENT.ITEMS.map((item) => (
          <DescriptionCard
            key={item.type}
            item={item}
            className={styles.solutionItemWrapper}
          />
        ))}
      </div>
      <div className={styles.wrapper}>
        <div className={styles.child40}>
          <h3>{CONTENT.FORM.TITLE}</h3>
          <p className={styles.description}>{CONTENT.FORM.DESCRIPTION}</p>
          <Form />
        </div>
        <div className={styles.child60}>
          <img
            src={imageDesktop}
            alt={CONTENT.TITLE}
            className={styles.imageWrapper}
          />
        </div>
      </div>
    </section>
  );
};

export default Format;
