import logo from "../../assets/images/logo.png";

import styles from "./styles.module.scss";

const Footer = () => {
  return (
    <footer className={styles.container}>
      <div className={styles.logo}>
        <img src={logo} alt="Logo" />
      </div>
      <div className={styles.copyright}>
        <p>&copy; 2024 Vadarod. Все права защищены.</p>
      </div>
    </footer>
  );
};

export default Footer;
