import { useEffect, useState } from "react";
import logo from "../../assets/images/logo.png";
import "./styles.scss";

import PhoneWithIcon from "./components/phone-with-icon";
import { MENU } from "./constant";
import { scrollToSection } from "../../utils/scroll-to-section";
import { getActiveMenuItem } from "../../utils/get-active-menu-item";
import { Navigation } from "../../types";
import classNames from "classnames";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState<null | Navigation>(null);

  useEffect(() => {
    const handleScroll = () => {
      const activeSectionId: null | Navigation = getActiveMenuItem();
      if (activeSection !== activeSectionId) setActiveSection(activeSectionId);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activeSection]);

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <header className={`header ${menuOpen ? "menu-open" : ""}`}>
      <div className="header-content">
        <div className="left-content">
          <div className="logo">
            <img src={logo} alt="Logo" />
          </div>
          <nav className={`menu ${menuOpen ? "open" : ""}`}>
            <ul>
              {MENU.map((item) => (
                <li
                  key={item.id}
                  onClick={() => scrollToSection(item.id)}
                  className={classNames(item.id === activeSection && "active")}
                >
                  {item.text}
                </li>
              ))}
            </ul>
            <PhoneWithIcon className="contact-mobile" />
          </nav>
        </div>
        <PhoneWithIcon className="contact-desktop" />
        <div className="menu-toggle" onClick={toggleMenu}>
          {menuOpen ? <span>&#10005;</span> : <span>&#9776;</span>}
        </div>
      </div>
    </header>
  );
};

export default Header;
