import classNames from "classnames";
import { PhoneCallIcon } from "../../../../assets/icons";
import { PHONE } from "../../constant";
import styles from "./styles.module.scss";

interface PhoneWithIconProps {
  className: string;
}

const PhoneWithIcon: React.FC<PhoneWithIconProps> = ({ className }) => {
  return (
    <div className={classNames(styles.container, className)}>
      <PhoneCallIcon />
      <a href={`tel:${PHONE.replaceAll(" ", "")}`}>{PHONE}</a>
    </div>
  );
};

export default PhoneWithIcon;
