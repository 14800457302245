import { Item } from "../../types";

export const CONTENT = {
  TITLE: "Возможности MariaChat",
  ITEMS: [
    {
      type: "chat",
      title: "Объединяйте все каналы на единой платформе",
      text: "MariaChat объединяет все популярные мессенджеры, социальные сети. Благодаря этому вы всегда на связи, оперативно отвечаете на запросы и увеличиваете продажи, не упуская ни одного обращения.",
    },
    {
      type: "smile",
      title: "Получите чат на сайте и в мобильном приложении",
      text: "Общайтесь с клиентам и решайте их запросы через интуитивно понятный чат виджет на вашем сайте или мобильном приложении.",
    },
    {
      type: "search",
      title:
        "Анализируйте взаимодействие с клиентами для принятия стратегических решений",
      text: "Получайте полную картину ваших коммуникаций с помощью наших интуитивно понятных дэшбордов и отчетов в реальном времени. Оптимизируйте свои процессы и повышайте удовлетворенность клиентов.",
    },
    {
      type: "target",
      title: "Получите полный контроль над вашими каналами и командой",
      text: "Благодаря обширным инструментам управления включайте и отключайте каналы в соответствии с графиком работы, настраивайте уведомления и управляйте правами доступа сотрудников.",
    },
  ] as Item[],
  BUTTON: "Заявка на демо",
};
