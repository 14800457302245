import { useMemo, useState } from "react";
import styles from "./styles.module.scss";
import { Button, CheckBox, Input } from "..";

import pdfFile1 from "../../../../../assets/docs/обработка_пдн.pdf";
import pdfFile2 from "../../../../../assets/docs/Политика_обработки_персональных_данных.pdf";
import { validateEmail, validateMessage, validatePhone } from "./helpers";

const Form = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [isChecked, setChecked] = useState(false);

  const handleClick = async () => {
    console.log("name", name);
    console.log("email", email);
    console.log("phone", phone);
    console.log("message", message);

    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("phone", phone);
      formData.append("message", message);

      const response = await fetch("https://formspree.io/f/mjkbbqbo", {
        method: "POST",
        body: formData,
      });

      setName("");
      setEmail("");
      setPhone("");
      setMessage("");
      setChecked(false);
    } catch (error) {
      setName("");
      setEmail("");
      setPhone("");
      setMessage("");
      setChecked(false);
    }
  };

  const isActive = useMemo(() => {
    return (
      validateEmail(email) &&
      validateMessage(name) &&
      validatePhone(phone) &&
      validateMessage(message) &&
      isChecked
    );
  }, [name, email, phone, message, isChecked]);

  return (
    <div className={styles.container}>
      <Input
        type="input"
        value={name}
        title="Имя"
        placeholder="Введите свое имя"
        onChange={setName}
      />
      <Input
        type="input"
        value={email}
        title="Email"
        placeholder="Введите свой Email"
        onChange={setEmail}
      />
      <Input
        type="phone"
        value={phone}
        title="Телефон"
        placeholder="+375 (29) 000-00-00"
        onChange={setPhone}
      />
      <Input
        type="textarea"
        value={message}
        title="Сообщение"
        placeholder="Оставьте нам сообщение..."
        onChange={setMessage}
      />
      <CheckBox
        label={
          <span>
            Даю свое согласие на обработку 
            <a href={pdfFile1} download="обработка_пдн.pdf">
              персональных данных
            </a>
             и соглашаюсь с 
            <a
              href={pdfFile2}
              download="Политика_обработки_персональных_данных.pdf"
            >
              политикой обработки персональных данных
            </a>
            .
          </span>
        }
        checked={isChecked}
        onChange={setChecked}
      />
      <div className={styles.buttonWrapper}>
        <Button
          text="Оставить заявку"
          onClick={handleClick}
          isDisabled={!isActive}
        />
      </div>
    </div>
  );
};

export default Form;
