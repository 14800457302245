import { isValidPhoneNumber } from "react-phone-number-input";

function validateEmail(email: string) {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  return emailRegex.test(email);
}

function validateUsername(username: string) {
  const usernameRegex = /^(?!.*[_.]{2})[a-zA-Z0-9._]{3,20}$/;

  return usernameRegex.test(username);
}

function validatePhone(phone: string) {
  return isValidPhoneNumber(phone);
}

function validateMessage(message: string) {
  return message.trim() !== "";
}

export { validateEmail, validateUsername, validatePhone, validateMessage };
