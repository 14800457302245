import classNames from "classnames";
import styles from "./styles.module.scss";
import { FC } from "react";
import "react-phone-number-input/style.css";
import PhoneInput, { type Value, Country } from "react-phone-number-input";

export type InputType = "input" | "textarea" | "phone";

interface IInputProps {
  type: InputType;
  placeholder?: string;
  value: string;
  title: string;
  onChange: (value: string) => void;
  className?: string;
}

const Input: FC<IInputProps> = ({
  type,
  placeholder = "",
  value,
  onChange,
  className,
  title,
}) => {
  const allowedCountries: Country[] = ["BY", "RU", "KZ"];

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onChange(event.target.value);
  };

  const handlePhoneInputChange = (value: Value) => {
    if (value) onChange(value);
  };

  const render = (type: InputType) => {
    switch (type) {
      case "input":
        return (
          <input
            className={classNames(styles.input, className)}
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={handleInputChange}
          />
        );
      case "textarea":
        return (
          <textarea
            className={classNames(styles.textarea, className)}
            placeholder={placeholder}
            value={value}
            onChange={handleInputChange}
          />
        );
      case "phone":
        return (
          <PhoneInput
            international
            defaultCountry="BY"
            value={value}
            onChange={handlePhoneInputChange}
            className={classNames(styles.phoneInputContainer, className)}
            countries={allowedCountries}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className={styles.container}>
      <span className={styles.title}>{title}</span>
      {render(type)}
    </div>
  );
};
export default Input;
