export function getActiveMenuItem() {
  const sections = document.querySelectorAll("section");

  const scrollPosition = window.scrollY;

  let activeSectionId = null;

  sections.forEach((section) => {
    const top = section.offsetTop - 50; // Учитываем отступ, если нужно
    const bottom = top + section.offsetHeight;

    if (scrollPosition >= top && scrollPosition < bottom) {
      activeSectionId = section.getAttribute("id");
    }
  });

  return activeSectionId;
}
