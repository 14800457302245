import { getIcon } from "../../../../../helpers/getIcon";
import { Item } from "../../../../../types";

interface IDescriptionCardProps {
  item: Item;
  className: string;
}

const DescriptionCard: React.FC<IDescriptionCardProps> = ({
  item,
  className,
}) => {
  return (
    <div className={className}>
      <div>{getIcon(item.type)}</div>
      <h4>{item.title}</h4>
      <p>{item.text}</p>
    </div>
  );
};

export default DescriptionCard;
