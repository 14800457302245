import {
  BuildingIcon,
  ChartBreakoutSquareIcon,
  ClockRefreshIcon,
  CloudIcon,
  FaceContentIcon,
  HourglassIcon,
  LineChartDownIcon,
  LineChartUpIcon,
  MessageChatSquareIcon,
  MessageSmileSquareIcon,
  MessageTextSquareIcon,
  Rocket,
  SearchRefractionIcon,
  SettingsIcon,
  Speedometer,
  TargetIcon,
  ZapIcon,
} from "../assets/icons";
import { ItemIcon } from "../types";

export const getIcon = (type: ItemIcon) => {
  switch (type) {
    case "building":
      return <BuildingIcon />;
    case "cloud":
      return <CloudIcon />;
    case "chat":
      return <MessageChatSquareIcon />;
    case "search":
      return <SearchRefractionIcon />;
    case "smile":
      return <MessageSmileSquareIcon />;
    case "target":
      return <TargetIcon />;
    case "square":
      return <MessageTextSquareIcon />;
    case "rocket":
      return <Rocket />;
    case "clock-refresh":
      return <ClockRefreshIcon />;
    case "zap":
      return <ZapIcon />;
    case "line-chart-down":
      return <LineChartDownIcon />;
    case "hourglass":
      return <HourglassIcon />;
    case "chart-breakout-square":
      return <ChartBreakoutSquareIcon />;
    case "settings":
      return <SettingsIcon />;
    case "face-content":
      return <FaceContentIcon />;
    case "line-chart-up":
      return <LineChartUpIcon />;
    case "speedometer":
      return <Speedometer />;
    default:
      return null;
  }
};
