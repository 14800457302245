import { FC } from "react";
import styles from "./styles.module.scss";
import imageDesktop from "../../../../../assets/images/changes_desktop.png";
import imageMobile from "../../../../../assets/images/changes_mobile.png";
import { getIcon } from "../../../../../helpers/getIcon";
import { CONTENT } from "../../../../../constants/content/changes";

interface IChangesProps {
  isMobile: boolean;
}

const Changes: FC<IChangesProps> = ({ isMobile }) => {
  return (
    <section className={styles.container}>
      <h2>{CONTENT.TITLE}</h2>
      <div className={styles.wrapper}>
        <div className={styles.content}>
          {CONTENT.ITEMS.map((item) => (
            <div className={styles.itemIconWrapper} key={item.type}>
              <div>{getIcon(item.type)}</div>
              <div className={styles.itemWrapper}>
                <h4>{item.title}</h4>
                <p>{item.text}</p>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.imageWrapper}>
          <img
            src={isMobile ? imageMobile : imageDesktop}
            alt={CONTENT.TITLE}
            className={styles.image}
          />
        </div>
      </div>
    </section>
  );
};

export default Changes;
