import { Item } from "../../types";

export const CONTENT = {
  TITLE: "Какие чат-боты есть у нас?",
  DESCRIPTION:
    "Вы можете подобрать подходящий инструмент для решения текущих бизнес задач компании",
  ITEMS: [
    {
      type: "square",
      title: "Сценарный чат-бот",
      text: "Инструмент отлично подходит для стандартных задач, таких как ответы на часто задаваемые вопросы или выполнение простых функций.",
    },
    {
      type: "rocket",
      title: "Умный чат-бот",
      text: "Бот использует искусственный интеллект для понимания запросов пользователя и предоставления более сложных ответов.",
    },
  ] as Item[],
};
