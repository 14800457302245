import styles from "./styles.module.scss";
import imageDesktop from "../../../../../assets/images/possibilities_desktop.png";
import imageMobile from "../../../../../assets/images/possibilities_mobile.png";
import { FC } from "react";
import { CONTENT } from "../../../../../constants/content/possibilities";
import { Button, DescriptionCard } from "../../shared";
import { scrollToSection } from "../../../../../utils/scroll-to-section";

interface IPossibilitiesProps {
  isMobile: boolean;
}

const Possibilities: FC<IPossibilitiesProps> = ({ isMobile }) => {
  return (
    <section className={styles.container} id="features">
      <h2>{CONTENT.TITLE}</h2>
      <div className={styles.wrapper}>
        <div className={styles.imageWrapper}>
          <img
            src={isMobile ? imageMobile : imageDesktop}
            alt={CONTENT.TITLE}
            className={styles.image}
          />
        </div>
        <div className={styles.grid}>
          {CONTENT.ITEMS.map((item) => (
            <DescriptionCard
              key={item.type}
              item={item}
              className={styles.itemWrapper}
            />
          ))}
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <Button text={CONTENT.BUTTON} onClick={() => scrollToSection("demo")} />
      </div>
    </section>
  );
};

export default Possibilities;
