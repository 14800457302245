import imageDesktop from "../../../../../assets/images/preview_desktop.png";
import imageMobile from "../../../../../assets/images/preview_mobile.png";
import styles from "./styles.module.scss";
import React from "react";
import { CONTENT } from "../../../../../constants/content/preview";
import { Button } from "../../shared";
import { scrollToSection } from "../../../../../utils/scroll-to-section";

interface IPreviewProps {
  isMobile: boolean;
}

const Preview: React.FC<IPreviewProps> = ({ isMobile }) => {
  return (
    <section className={styles.container}>
      <div className={styles.wrapper}>
        <h1>
          {CONTENT.TITLE.split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              {index < CONTENT.TITLE.split("\n").length - 1 && <br />}
            </React.Fragment>
          ))}
        </h1>
        <p className="description">{CONTENT.DESCRIPTION}</p>
        <div className={styles.buttonsWrapper}>
          <Button
            text={CONTENT.BUTTON1}
            onClick={() => scrollToSection("features")}
          />
          <Button
            text={CONTENT.BUTTON2}
            type="icon"
            onClick={() => scrollToSection("demo")}
          />
        </div>
      </div>
      <div className={styles.imageWrapper}>
        <img
          src={isMobile ? imageMobile : imageDesktop}
          alt={CONTENT.TITLE}
          className={styles.image}
        />
      </div>
    </section>
  );
};

export default Preview;
