const HourglassIcon = () => {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_204_3891)">
        <path
          d="M2 25C2 11.7452 12.7452 1 26 1C39.2548 1 50 11.7452 50 25C50 38.2548 39.2548 49 26 49C12.7452 49 2 38.2548 2 25Z"
          fill="#A1CFD1"
          shapeRendering="crispEdges"
        />
        <path
          d="M2.5 25C2.5 12.0213 13.0213 1.5 26 1.5C38.9787 1.5 49.5 12.0213 49.5 25C49.5 37.9787 38.9787 48.5 26 48.5C13.0213 48.5 2.5 37.9787 2.5 25Z"
          stroke="#EAECF0"
          shapeRendering="crispEdges"
        />
        <path
          d="M26 25L21.7271 21.4393C21.0923 20.9102 20.7748 20.6457 20.5466 20.3214C20.3444 20.0341 20.1943 19.7135 20.103 19.3743C20 18.9914 20 18.5782 20 17.7518V15M26 25L30.2729 21.4393C30.9077 20.9102 31.2252 20.6457 31.4534 20.3214C31.6556 20.0341 31.8057 19.7135 31.897 19.3743C32 18.9914 32 18.5782 32 17.7518V15M26 25L21.7271 28.5607C21.0923 29.0898 20.7748 29.3543 20.5466 29.6786C20.3444 29.9659 20.1943 30.2865 20.103 30.6257C20 31.0086 20 31.4218 20 32.2482V35M26 25L30.2729 28.5607C30.9077 29.0898 31.2252 29.3543 31.4534 29.6786C31.6556 29.9659 31.8057 30.2865 31.897 30.6257C32 31.0086 32 31.4218 32 32.2482V35M18 15H34M18 35H34"
          stroke="#101828"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_204_3891"
          x="0"
          y="0"
          width="52"
          height="52"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_204_3891"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_204_3891"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default HourglassIcon;
