import { ReactNode } from "react";
import styles from "./styles.module.scss";

interface CheckBoxProps {
  label: ReactNode;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const CheckBox: React.FC<CheckBoxProps> = ({
  label,
  checked,
  onChange,
  ...props
}) => {
  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    onChange(isChecked);
  };

  return (
    <label className={styles.checkboxContainer}>
      <input
        type="checkbox"
        {...props}
        checked={checked}
        onChange={handleCheckBoxChange}
      />
      <span className={styles.checkmark}></span>
      {label}
    </label>
  );
};

export default CheckBox;
