const SearchRefractionIcon = () => {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_192_2190)">
        <path
          d="M2 25C2 11.7452 12.7452 1 26 1C39.2548 1 50 11.7452 50 25C50 38.2548 39.2548 49 26 49C12.7452 49 2 38.2548 2 25Z"
          fill="#A1CFD1"
          shapeRendering="crispEdges"
        />
        <path
          d="M2.5 25C2.5 12.0213 13.0213 1.5 26 1.5C38.9787 1.5 49.5 12.0213 49.5 25C49.5 37.9787 38.9787 48.5 26 48.5C13.0213 48.5 2.5 37.9787 2.5 25Z"
          stroke="#EAECF0"
          shapeRendering="crispEdges"
        />
        <path
          d="M35 34L30.65 29.65M25 19C27.7614 19 30 21.2386 30 24M33 24C33 28.4183 29.4183 32 25 32C20.5817 32 17 28.4183 17 24C17 19.5817 20.5817 16 25 16C29.4183 16 33 19.5817 33 24Z"
          stroke="#344054"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_192_2190"
          x="0"
          y="0"
          width="52"
          height="52"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_192_2190"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_192_2190"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default SearchRefractionIcon;
