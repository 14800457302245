import { Navigation } from "../types";

export const scrollToSection = (id: Navigation) => {
  const section = document.getElementById(id);

  if (section) {
    const yOffset = id === "demo" ? +250 : -40;
    const y =
      section.getBoundingClientRect().top + window.pageYOffset + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  }
};
