export const CONTENT = {
  TITLE: `
      MariaChat —
      ключ к эффективной коммуникации
    `,
  DESCRIPTION:
    "Омниканальная платформа, которая позволяет всегда оставаться на связи с клиентами через любые каналы общения",
  BUTTON1: "Возможности",
  BUTTON2: "Заявка на демо",
};
