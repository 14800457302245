const Speedometer = () => {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_204_4034)">
        <path
          d="M2 25C2 11.7452 12.7452 1 26 1C39.2548 1 50 11.7452 50 25C50 38.2548 39.2548 49 26 49C12.7452 49 2 38.2548 2 25Z"
          fill="white"
          shapeRendering="crispEdges"
        />
        <path
          d="M2.5 25C2.5 12.0213 13.0213 1.5 26 1.5C38.9787 1.5 49.5 12.0213 49.5 25C49.5 37.9787 38.9787 48.5 26 48.5C13.0213 48.5 2.5 37.9787 2.5 25Z"
          stroke="#EAECF0"
          shapeRendering="crispEdges"
        />
        <path
          d="M36 25C36 30.5228 31.5228 35 26 35C20.4772 35 16 30.5228 16 25M36 25C36 19.4772 31.5228 15 26 15M36 25H33.5M16 25C16 19.4772 20.4772 15 26 15M16 25H18.5M26 15V17.5M33.0784 18L27.4999 23.5M33.0784 32.0784L32.8745 31.8745C32.1827 31.1827 31.8368 30.8368 31.4331 30.5894C31.0753 30.3701 30.6851 30.2085 30.2769 30.1105C29.8166 30 29.3274 30 28.349 30L23.651 30C22.6726 30 22.1834 30 21.7231 30.1106C21.3149 30.2086 20.9248 30.3702 20.5669 30.5895C20.1632 30.8369 19.8173 31.1828 19.1255 31.8746L18.9216 32.0784M18.9216 18L20.6581 19.7365M28 25C28 26.1046 27.1046 27 26 27C24.8954 27 24 26.1046 24 25C24 23.8954 24.8954 23 26 23C27.1046 23 28 23.8954 28 25Z"
          stroke="#344054"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_204_4034"
          x="0"
          y="0"
          width="52"
          height="52"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_204_4034"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_204_4034"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Speedometer;
