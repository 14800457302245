import { Item } from "../../types";

export const CONTENT = {
  TITLE: "Выберите удобный формат",
  ITEMS: [
    {
      type: "building",
      title: "Enterprise",
      text: "Полный контроль, высокая безопасность, глубокая настройка, легкая интеграция, однократные и постоянные затраты.",
    },
    {
      type: "cloud",
      title: "Cloud",
      text: "Гибкость масштабирования, автоматические обновления, упрощение совместной работы, оплата по факту использования, высокая доступность.",
    },
  ] as Item[],
  FORM: {
    TITLE: "Начните использовать MariaChat уже сегодня",
    DESCRIPTION: "Оставьте заявку на демо",
  },
  BUTTON: "Оставить заявку",
};

// agreement
