import { Item } from "../../types";

export const CONTENT = {
  TITLE: "Что изменится?",
  ITEMS: [
    {
      type: "face-content",
      title: "Поддержка клиентов",
      text: "Обеспечение круглосуточной поддержки с помощью автоматизированных ответов и интеллектуального распределения запросов между операторами.",
    },
    {
      type: "line-chart-up",
      title: "Продажа продуктов",
      text: "Рост продаж за счет увеличения каналов коммуникации между клиентами и бизнесом, обеспечивая более широкие возможности для взаимодействия.",
    },
    {
      type: "speedometer",
      title: "Автоматизация процессов",
      text: "Сокращение времени реакции на запросы клиентов и уменьшение нагрузки на операторов благодаря автоматизации рутинных запросов.",
    },
  ] as Item[],
};
