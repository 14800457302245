const ClockRefreshIcon = () => {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_204_3875)">
        <path
          d="M2 25C2 11.7452 12.7452 1 26 1C39.2548 1 50 11.7452 50 25C50 38.2548 39.2548 49 26 49C12.7452 49 2 38.2548 2 25Z"
          fill="#A1CFD1"
          shapeRendering="crispEdges"
        />
        <path
          d="M2.5 25C2.5 12.0213 13.0213 1.5 26 1.5C38.9787 1.5 49.5 12.0213 49.5 25C49.5 37.9787 38.9787 48.5 26 48.5C13.0213 48.5 2.5 37.9787 2.5 25Z"
          stroke="#EAECF0"
          shapeRendering="crispEdges"
        />
        <path
          d="M34.4532 25.8928C34.1754 28.5027 32.6967 30.9484 30.2497 32.3612C26.1842 34.7084 20.9857 33.3155 18.6385 29.25L18.3885 28.817M17.5462 24.1071C17.824 21.4972 19.3028 19.0515 21.7497 17.6387C25.8152 15.2915 31.0138 16.6845 33.361 20.75L33.611 21.183M17.4932 31.0659L18.2252 28.3339L20.9573 29.0659M31.0422 20.934L33.7743 21.666L34.5063 18.934M25.9997 20.5V25L28.4997 26.5"
          stroke="#101828"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_204_3875"
          x="0"
          y="0"
          width="52"
          height="52"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_204_3875"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_204_3875"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default ClockRefreshIcon;
