import styles from "./styles.module.scss";
import { CONTENT } from "../../../../../constants/content/for-what";
import { Button, DescriptionCard } from "../../shared";
import { scrollToSection } from "../../../../../utils/scroll-to-section";

const ForWhat = () => {
  return (
    <section className={styles.container}>
      <h2>{CONTENT.TITLE}</h2>
      <div className={styles.grid}>
        {CONTENT.ITEMS.map((item) => (
          <DescriptionCard
            key={item.type}
            item={item}
            className={styles.item}
          />
        ))}
      </div>
      <div className={styles.buttonWrapper}>
        <Button text={CONTENT.BUTTON} onClick={() => scrollToSection("demo")} />
      </div>
    </section>
  );
};

export default ForWhat;
