const PhoneCallIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_120_2903)">
        <path
          d="M11.708 4.99996C12.522 5.15876 13.27 5.55684 13.8564 6.14324C14.4428 6.72964 14.8409 7.47768 14.9997 8.29163M11.708 1.66663C13.3991 1.85449 14.976 2.61177 16.1799 3.81413C17.3838 5.01649 18.143 6.59247 18.333 8.28329M8.52219 11.5525C7.52088 10.5512 6.73022 9.419 6.15022 8.21098C6.10033 8.10708 6.07539 8.05512 6.05622 7.98938C5.98812 7.75575 6.03704 7.46887 6.17871 7.27101C6.21858 7.21533 6.26621 7.1677 6.36147 7.07244C6.65281 6.7811 6.79848 6.63544 6.89372 6.48895C7.25288 5.93654 7.25288 5.22439 6.89372 4.67199C6.79848 4.5255 6.65281 4.37984 6.36147 4.0885L6.19908 3.92611C5.75621 3.48324 5.53478 3.2618 5.29696 3.14151C4.82399 2.90229 4.26544 2.90229 3.79247 3.14152C3.55465 3.2618 3.33322 3.48324 2.89035 3.92611L2.75899 4.05747C2.31763 4.49882 2.09696 4.7195 1.92842 5.01952C1.7414 5.35244 1.60693 5.86952 1.60807 6.25137C1.60909 6.59549 1.67585 6.83068 1.80935 7.30105C2.52683 9.82888 3.88056 12.2142 5.87054 14.2042C7.86052 16.1942 10.2458 17.5479 12.7737 18.2654C13.244 18.3989 13.4792 18.4656 13.8233 18.4666C14.2052 18.4678 14.7223 18.3333 15.0552 18.1463C15.3552 17.9777 15.5759 17.7571 16.0172 17.3157L16.1486 17.1844C16.5915 16.7415 16.8129 16.5201 16.9332 16.2822C17.1724 15.8093 17.1724 15.2507 16.9332 14.7777C16.8129 14.5399 16.5915 14.3185 16.1486 13.8756L15.9862 13.7132C15.6949 13.4219 15.5492 13.2762 15.4027 13.181C14.8503 12.8218 14.1382 12.8218 13.5858 13.181C13.4393 13.2762 13.2936 13.4219 13.0023 13.7132C12.907 13.8085 12.8594 13.8561 12.8037 13.896C12.6058 14.0377 12.319 14.0866 12.0853 14.0185C12.0196 13.9993 11.9676 13.9744 11.8637 13.9245C10.6557 13.3445 9.52351 12.5538 8.52219 11.5525Z"
          stroke="#475467"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_120_2903">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PhoneCallIcon;
