const Rocket = () => {
  return (
    <svg
      width="52"
      height="52"
      viewBox="0 0 52 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_199_123)">
        <path
          d="M2 25C2 11.7452 12.7452 1 26 1C39.2548 1 50 11.7452 50 25C50 38.2548 39.2548 49 26 49C12.7452 49 2 38.2548 2 25Z"
          fill="white"
          shapeRendering="crispEdges"
        />
        <path
          d="M2.5 25C2.5 12.0213 13.0213 1.5 26 1.5C38.9787 1.5 49.5 12.0213 49.5 25C49.5 37.9787 38.9787 48.5 26 48.5C13.0213 48.5 2.5 37.9787 2.5 25Z"
          stroke="#EAECF0"
          shapeRendering="crispEdges"
        />
        <path
          d="M26 28L23 25M26 28C27.3968 27.4687 28.7369 26.7987 30 26M26 28V33C26 33 29.03 32.45 30 31C31.08 29.38 30 26 30 26M23 25C23.5321 23.6194 24.2022 22.2961 25 21.05C26.1652 19.187 27.7876 17.6531 29.713 16.5941C31.6384 15.5351 33.8027 14.9864 36 15C36 17.72 35.22 22.5 30 26M23 25H18C18 25 18.55 21.97 20 21C21.62 19.92 25 21 25 21M18.5 29.5C17 30.76 16.5 34.5 16.5 34.5C16.5 34.5 20.24 34 21.5 32.5C22.21 31.66 22.2 30.37 21.41 29.59C21.0213 29.219 20.5093 29.0046 19.9722 28.988C19.4352 28.9714 18.9109 29.1537 18.5 29.5Z"
          stroke="#344054"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_199_123"
          x="0"
          y="0"
          width="52"
          height="52"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_199_123"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_199_123"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Rocket;
