import { FC } from "react";
import { ArrowRightIcon } from "../../../../../assets/icons";
import classNames from "classnames";
import styles from "./styles.module.scss";

interface IButtonProps {
  text: string;
  type?: "default" | "icon";
  onClick?: () => void;
  isDisabled?: boolean;
}

const Button: FC<IButtonProps> = ({
  text,
  type = "default",
  onClick,
  isDisabled = false,
}) => {
  const btnColor = type === "default" ? styles.color : styles.no_color;

  const handleClick = () => !isDisabled && onClick && onClick();

  return (
    <div
      className={classNames(
        styles.container,
        btnColor,
        isDisabled && styles.disabled
      )}
      onClick={handleClick}
    >
      {text}
      {type === "icon" && <ArrowRightIcon />}
    </div>
  );
};

export default Button;
